import Seo from '../components/blog/seo'
import { fetchSEO } from '../lib/blog/api'
import LandingWrapper from '../components/landing/elements/LandingWrapper'
import SectionSofaTypes from '../components/ivystore/SectionSofaTypes'
// import SectionBrands from '../components/ivystore/SectionBrands'
import SectionAdvantages from '../components/ivystore/SectionAdvantages'
import SectionFindCouch from '../components/ivystore/SectionFindCouch'
// import SectionArticles from '../components/ivystore/SectionArticles'
// import SectionFaqs from '../components/ivystore/SectionFaqs'
import SectionIntro from '../components/ivystore/SectionIntro'
import baseUrl from '../lib/base-url'
import SectionActiveStates from '../components/ivystore/SectionActiveStates'
import SectionCouchRemoval from '../components/ivystore/SectionCouchRemoval'

const Index = ({ seo, statesData }) => {
  return (
    <>
      <Seo seo={seo} />

      <LandingWrapper gap={'4rem'}>
        <SectionIntro
          title="Find Your Dream Couch up to 70% less than Retail"
          text="Amazing Deals from Local, Vetted Sellers w/ Lightning-Fast Delivery."
        />

        <SectionActiveStates
          statesData={statesData}
        />

        <SectionFindCouch />

        <SectionSofaTypes />

        <SectionAdvantages />

        <SectionCouchRemoval />

        {/*<SectionBrands brands={brands} />*/}
        {/*<SectionArticles articles={articles} />*/}
        {/*<SectionFaqs items={faqItems} />*/}
      </LandingWrapper>
    </>
  )
}

export async function getStaticProps () {
  const seo = await fetchSEO('index')

  let statesData = null

  try {
    const geographyResponse = await fetch(`${baseUrl}/api/ivy-store/geography`)
    const { data } = await geographyResponse.json()
    statesData = data
  } catch (error) {
    console.error('Failed to fetch states data:', error.message)
  }

  return {
    props: {
      seo,
      statesData
    },
    revalidate: 60 * 60 * 24,
  }
}

export default Index
