import Block from '../landing/elements/Block'
import Col from 'react-bootstrap/Col'
import Link from 'next/link'
import Card from '../landing/elements/Card'
import Row from 'react-bootstrap/Row'

export default function SectionActiveStates ({ statesData }) {
  if (!statesData) {
    return null
  }

  const statesGroupedByLetter = statesData.reduce((acc, state) => {
    const letter = state.name[0].toUpperCase()
    if (!acc[letter]) {
      acc[letter] = []
    }
    acc[letter].push(state)
    return acc
  }, {})

  return (
    <Block>
      <Col xs={12}>
        <Card gap={5}>
          <h2
            className={'fs-4 m-0'}
          >
            Explore Local Couch Deals
          </h2>

          <Row className={'g-2'}>
            {statesGroupedByLetter && Object.keys(statesGroupedByLetter).map(letter => (
              <Col
                key={letter}
                xs={4} md={2}
              >
                <div
                  className={'fs-3 fw-semibold ps-1 mb-1'}
                >
                  {letter}
                </div>

                <ul className={'list-unstyled'}>
                  {statesGroupedByLetter[letter].map(stateData => {
                    return (
                      <li
                        key={stateData.code}
                      >
                        <Link
                          href={`/${stateData.slug}`}
                          className={'btn btn-outline-primary border-0 btn-sm'}
                        >
                          {stateData.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>
    </Block>
  )
}